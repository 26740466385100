import React, { useState} from 'react';
import { Input, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import GoogleMapComponent from './GoogleMapComponent';
import {
    LoadScript,
    Autocomplete,
} from '@react-google-maps/api';

const Edit = (props) => {
    const { index, value, handleChange, setFieldValue, onLoad, onPlaceChanged } = props;
    const [libraries] = useState(['places']);

    return (
        <div>
            <LoadScript
                googleMapsApiKey='AIzaSyBsPUPp5lYJ0na4qTSzMIRav58UPWxuhFQ'
                libraries={libraries}
            >
                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Outlet Name
                    </label>
                    <Input
                        className='form-control-alternative'
                        type='text'
                        id={`${index}.outlets.name`}
                        name={`outlets.${index}.name`}
                        value={value.name}
                        onChange={handleChange}
                        placeholder='Outlet Name  '
                    />
                </FormGroup>

                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Address
                    </label>
                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={() => onPlaceChanged(index, setFieldValue)}
                    >
                        <Input
                            type='text'
                            className='form-control-alternative'
                            id={`outlets.${index}.formatted_address`}
                            name={`outlets.${index}.formatted_address`}
                            value={value.formatted_address}
                            onChange={handleChange}
                            placeholder='Blk 998 Toa Payoh North #01-09, Singapore 318993'
                        />
                    </Autocomplete>
                    {/* <Input
                    className='form-control-alternative'
                    type='text'
                    id={`outlets.${index}.address`}
                    name={`outlets.${index}.address`}
                    value={value.address}
                    onChange={handleChange}
                    placeholder='Blk 998 Toa Payoh North #01-09, Singapore 318993'
                /> */}
                </FormGroup>
                    
                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Google Map
                    </label>
                    <div
                        id='google-map-capture'
                        style={{ width: '100%', height: '300px' }}
                    >
                        <GoogleMapComponent
                            onLoad={onLoad}
                            index={index}
                            onPlaceChanged={onPlaceChanged}
                            setFieldValue={setFieldValue}
                            latitude={value.latitude}
                            longitude={value.longitude}
                        />
                    </div>
                </FormGroup>

                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Fixed line/Mobile
                    </label>
                    <Input
                        className='form-control-alternative'
                        type='text'
                        id={`outlets.${index}.contactNumber`}
                        name={`outlets.${index}.contactNumber`}
                        value={value.contactNumber}
                        onChange={handleChange}
                        placeholder='Phone Number'
                    />
                </FormGroup>

                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        WhatsApp Number
                    </label>
                    <Input
                        className='form-control-alternative'
                        type='text'
                        id={`outlets.${index}.whatsAppNumber`}
                        name={`outlets.${index}.whatsAppNumber`}
                        value={value.whatsAppNumber}
                        onChange={handleChange}
                        placeholder='+65978945612'
                    />
                </FormGroup>

                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Email
                    </label>
                    <Input
                        className='form-control-alternative'
                        type='text'
                        id={`outlets.${index}.email`}
                        name={`outlets.${index}.email`}
                        value={value.email}
                        onChange={handleChange}
                        placeholder='sales@yourname.com'
                    />
                </FormGroup>

                <FormGroup>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Website
                    </label>
                    <Input
                        className='form-control-alternative'
                        type='text'
                        id={`outlets.${index}.website`}
                        name={`outlets.${index}.website`}
                        value={value.website}
                        onChange={handleChange}
                        placeholder='www.yourwebsite.com'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Monday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.monday`}
                        name={`outlets.${index}.monday`}
                        value={value?.monday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Tuesday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.tuesday`}
                        name={`outlets.${index}.tuesday`}
                        value={value?.tuesday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Wednesday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.wednesday`}
                        name={`outlets.${index}.wednesday`}
                        value={value?.wednesday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Thursday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.thursday`}
                        name={`outlets.${index}.thursday`}
                        value={value?.thursday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Friday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.friday`}
                        name={`outlets.${index}.friday`}
                        value={value?.friday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Saturday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.saturday`}
                        name={`outlets.${index}.saturday`}
                        value={value?.saturday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>

                <FormGroup className='mb-1'>
                    <label
                        className='form-control-label mb-0 text-xs'
                    >
                        Sunday
                    </label>
                    <Input
                        className='form-control-alternative w-25'
                        type='text'
                        id={`outlets.${index}.sunday`}
                        name={`outlets.${index}.sunday`}
                        value={value?.sunday}
                        onChange={handleChange}
                        placeholder='9am to 6pm'
                    />
                </FormGroup>
            </LoadScript>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
