/* eslint-disable */
import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "300px",
};

let autoComplete;

function GoogleMapComponent({
  index,
  onLoad,
  onPlaceChanged,
  setFieldValue,
  latitude,
  longitude,
}) {
  const [libraries] = useState(["places"]);

  var center = {
    lat: latitude ? parseFloat(latitude) : -3.745,
    lng: longitude ? parseFloat(longitude) : -38.523,
  };

  return (
    // <LoadScript
    //   googleMapsApiKey='AIzaSyBsPUPp5lYJ0na4qTSzMIRav58UPWxuhFQ'
    //   libraries={libraries}
    // >
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        {/* Child components, such as markers, info windows, etc. */}
        {/* <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={() => onPlaceChanged(index, setFieldValue)}
        >
          <input
            type='text'
            placeholder='Search Location...'
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
            style={{
              border: `1px solid transparent`,
              width: `239px`,
              height: `48px`,
              padding: `0 12px`,
              fontSize: `14px`,
              color: "#A8A6A6",
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              right: "10%",
              marginLeft: "-120px",
              top: "2%",
              borderRadius: "10px",
            }}
          />
        </Autocomplete> */}
        <Marker position={center} />
      </GoogleMap>
    // </LoadScript>
  );
}

export default React.memo(GoogleMapComponent);
