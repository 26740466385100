import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { Field } from 'formik';
import { withRouter } from 'react-router';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/common/plus.svg';
import { useComponents } from '../../../../zustand/componentStore.js';

import {
    Row,
    Col,
    Button,
    Input,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import DraggableComponent from '../DraggableComponent.js';
import ComponentSelector from '../ComponentSelector.js';
import PricingRuleSelector from '../PricingRuleSelector.js';
import MarginProfileSelector from '../MarginProfileSelector.js';
import MarginSection from '../MarginSection.js';
import CustomFieldSection from '../CustomFieldSection.js';
import DynamicPriceSection from '../DynamicPriceSection.js';

const BookletComponentStructure = (props) => {
    const {
        packageDetails,
        userId,
        values,
        setFieldValue,
        existingProduct,
        marginsData,
        marginProfilesData,
        dynamicPriceData,
        role,
        notificationOpen,
        componentsTypeData,
    } = props;

    const { actions } = useComponents();

    const getComponentTypeId = (name) => {
        return componentsTypeData.find((x) => x.name === name)?._id;
    };

    useEffect(() => {
        if ((values?.bookletData?.coverComponents?.materialComponents !== existingProduct?.bookletData?.coverComponents?.materialComponents)
            || (values?.bookletData?.contentComponents?.materialComponents !== existingProduct?.bookletData?.contentComponents?.materialComponents))
        {
            const newC2CPairing = values.bookletData.coverComponents.materialComponents.map((cover) => {
                return {
                    componentId: cover.componentId,
                    cover: cover.name,
                    content: values.bookletData.contentComponents.materialComponents.map((content) => {
                        return {
                            componentId: content.componentId,
                            name: content.name,
                            enabled: true,
                        };
                    })
                };
            });
            // newC2CPairing?.map(x => {
            //     existingProduct?.bookletData?.coverToContentPairing.map((k) => {
            //         if (k.componentId === x.componentId) {
            //             x?.content?.map((content) => {
            //                 k?.content?.map((content2) => {
            //                     if (content2.componentId === content.componentId) {
            //                         content.maxPP = content2.maxPP;
            //                         content.minPP = content2.minPP;
            //                     }
            //                 });
            //             });
            //         }
            //     });
            // });
            setFieldValue('bookletData.coverToContentPairing', newC2CPairing);
        }
    }, [
        values.bookletData.coverComponents,
        values.bookletData.contentComponents,
    ]);

    useEffect(() => {
        console.log(values);
    }, [
        values,
    ]);

    return (
        <>
            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Binding Type*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        disabled={values.bookletData.bindingTypeComponents.length > 0}
                        onClick={() => [
                            actions.setStoredLocation('bookletData.bindingTypeComponents'),
                            actions.setSelectedComponentType('Binding Type'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Binding Type')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='bookletData.bindingTypeComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                            {values.bookletData.bindingTypeComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.bookletData.bindingTypeComponents}
                                    supportNested={false}
                                    location={'bookletData.bindingTypeComponents'}
                                    description={c.description}
                                />                                                                                                  
                            ))}
                                
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Printing Size*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        disabled={values.bookletData.printSizeComponents.length > 0}
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('bookletData.printSizeComponents'),
                            actions.setSelectedComponentType('Printing Size'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Printing Size')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='bookletData.printSizeComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                            {values.bookletData.printSizeComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.bookletData.printSizeComponents}
                                    supportNested={false}
                                    location={'bookletData.printSizeComponents'}
                                    description={c.description}
                                />
                            ))}
                                
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded border p-3 mb-3'>
                <h4 className='m-0 mb-3 text-center'>Cover Setting</h4>
                <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                    <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                        <h4 className='m-0'>Material*</h4>
                        <Button
                            color='primary'
                            className='shadow-none rounded-circle p-1 m-0 d-flex'
                            style={{ height: 25, width: 25 }}
                            onClick={() => [
                                actions.setStoredLocation('bookletData.coverComponents.materialComponents'),
                                actions.setSelectedComponentType('Material'),
                                actions.setSelectedComponentTypeId(getComponentTypeId('Material')),
                                actions.setModalAllowBack(false),
                                actions.setAddComponentModalIsOpen(true),
                                actions.setBookletSetting('cover'),
                            ]}
                        >
                            <PlusIcon
                                height={15}
                                width={15}
                            />
                        </Button>
                    </Row>
                
                    <Droppable droppableId='bookletData.coverComponents.materialComponents'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                {values.bookletData.coverComponents.materialComponents.map((c, index) => (
                                    <DraggableComponent
                                        key={`${c._id ?? c.name}-${index}`}
                                        values={values}
                                        index={index}
                                        name={c.name}
                                        componentTypeName={c.componentTypeName}
                                        code={c.code}
                                        setFieldValue={setFieldValue}
                                        component={c}
                                        selectedComponents={values.bookletData.coverComponents.materialComponents}
                                        supportNested={true}
                                        location={'bookletData.coverComponents.materialComponents'}
                                        description={c.description}
                                    />                                                                                                  
                                ))}
                                
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                
                <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                    <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                        <h4 className='m-0'>Printing Cost*</h4>
                        <Button
                            color='primary'
                            className='shadow-none rounded-circle p-1 m-0 d-flex'
                            disabled={values.bookletData.coverComponents.printCostComponents.length > 0}
                            style={{ height: 25, width: 25 }}
                            onClick={() => [
                                actions.setStoredLocation('bookletData.coverComponents.printCostComponents'),
                                actions.setSelectedComponentType('Printing Cost'),
                                actions.setSelectedComponentTypeId(getComponentTypeId('Printing Cost')),
                                actions.setModalAllowBack(false),
                                actions.setAddComponentModalIsOpen(true),
                            ]}
                        >
                            <PlusIcon
                                height={15}
                                width={15}
                            />
                        </Button>
                    </Row>
                
                    <Droppable droppableId='bookletData.coverComponents.printCostComponents'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                {values.bookletData.coverComponents.printCostComponents.map((c, index) => (
                                    <DraggableComponent
                                        key={`${c._id ?? c.name}-${index}`}
                                        values={values}
                                        index={index}
                                        name={c.name}
                                        componentTypeName={c.componentTypeName}
                                        code={c.code}
                                        setFieldValue={setFieldValue}
                                        component={c}
                                        selectedComponents={values.bookletData.coverComponents.printCostComponents}
                                        supportNested={false}
                                        location={'bookletData.coverComponents.printCostComponents'}
                                        description={c.description}
                                    />                                                                                                  
                                ))}
                                
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>

                <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                    <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                        <h4 className='m-0'>Additional Component</h4>
                        <Button
                            color='primary'
                            className='shadow-none rounded-circle p-1 m-0 d-flex'
                            style={{ height: 25, width: 25 }}
                            onClick={() => [
                                actions.setStoredLocation('bookletData.coverComponents.additionalComponents'),
                                actions.setSelectedComponentType('Options'),
                                actions.setSelectedComponentTypeId(getComponentTypeId('')),
                                actions.setModalAllowBack(true),
                                actions.setExcludedComponentTypes(['Material', 'Binding Type', 'Printing Size', 'Printing Cost', 'Quantity', 'Production Timeline', 'Artwork Service', 'File Storage']),
                                actions.setAddComponentModalIsOpen(true),
                            ]}
                        >
                            <PlusIcon
                                height={15}
                                width={15}
                            />
                        </Button>
                    </Row>
                
                    <Droppable droppableId='bookletData.coverComponents.additionalComponents'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                {values.bookletData.coverComponents.additionalComponents.map((c, index) => (
                                    <DraggableComponent
                                        key={`${c._id ?? c.name}-${index}`}
                                        values={values}
                                        index={index}
                                        name={c.name}
                                        componentTypeName={c.componentTypeName}
                                        code={c.code}
                                        setFieldValue={setFieldValue}
                                        component={c}
                                        selectedComponents={values.bookletData.coverComponents.additionalComponents}
                                        supportNested={false}
                                        location={'bookletData.coverComponents.additionalComponents'}
                                        description={c.description}
                                    />                                                                                                  
                                ))}
                                
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>

            <div className='rounded border p-3 mb-3'>
                <h4 className='m-0 mb-3 text-center'>Content Setting</h4>
                <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                    <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                        <h4 className='m-0'>Material*</h4>
                        <Button
                            color='primary'
                            className='shadow-none rounded-circle p-1 m-0 d-flex'
                            style={{ height: 25, width: 25 }}
                            onClick={() => [
                                actions.setStoredLocation('bookletData.contentComponents.materialComponents'),
                                actions.setSelectedComponentType('Material'),
                                actions.setSelectedComponentTypeId(getComponentTypeId('Material')),
                                actions.setModalAllowBack(false),
                                actions.setAddComponentModalIsOpen(true),
                                actions.setBookletSetting('content'),
                            ]}
                        >
                            <PlusIcon
                                height={15}
                                width={15}
                            />
                        </Button>
                    </Row>
                
                    <Droppable droppableId='bookletData.contentComponents.materialComponents'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                {values.bookletData.contentComponents.materialComponents.map((c, index) => (
                                    <DraggableComponent
                                        key={`${c._id ?? c.name}-${index}`}
                                        values={values}
                                        index={index}
                                        name={c.name}
                                        componentTypeName={c.componentTypeName}
                                        code={c.code}
                                        setFieldValue={setFieldValue}
                                        component={c}
                                        selectedComponents={values.bookletData.contentComponents.materialComponents}
                                        supportNested={true}
                                        location={'bookletData.contentComponents.materialComponents'}
                                        description={c.description}
                                    />                                                                                                  
                                ))}
                                
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                
                <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                    <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                        <h4 className='m-0'>Printing Cost*</h4>
                        <Button
                            color='primary'
                            className='shadow-none rounded-circle p-1 m-0 d-flex'
                            disabled={values.bookletData.contentComponents.printCostComponents.length > 0}
                            style={{ height: 25, width: 25 }}
                            onClick={() => [
                                actions.setStoredLocation('bookletData.contentComponents.printCostComponents'),
                                actions.setSelectedComponentType('Printing Cost'),
                                actions.setSelectedComponentTypeId(getComponentTypeId('Printing Cost')),
                                actions.setModalAllowBack(false),
                                actions.setAddComponentModalIsOpen(true),
                            ]}
                        >
                            <PlusIcon
                                height={15}
                                width={15}
                            />
                        </Button>
                    </Row>
                
                    <Droppable droppableId='bookletData.contentComponents.printCostComponents'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                {values.bookletData.contentComponents.printCostComponents.map((c, index) => (
                                    <DraggableComponent
                                        key={`${c._id ?? c.name}-${index}`}
                                        values={values}
                                        index={index}
                                        name={c.name}
                                        componentTypeName={c.componentTypeName}
                                        code={c.code}
                                        setFieldValue={setFieldValue}
                                        component={c}
                                        selectedComponents={values.bookletData.contentComponents.printCostComponents}
                                        supportNested={false}
                                        location={'bookletData.contentComponents.printCostComponents'}
                                        description={c.description}
                                    />                                                                                                  
                                ))}
                                
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>

                <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                    <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                        <h4 className='m-0'>Additional Component</h4>
                        <Button
                            color='primary'
                            className='shadow-none rounded-circle p-1 m-0 d-flex'
                            style={{ height: 25, width: 25 }}
                            onClick={() => [
                                actions.setStoredLocation('bookletData.contentComponents.additionalComponents'),
                                actions.setSelectedComponentType('Options'),
                                actions.setSelectedComponentTypeId(getComponentTypeId('')),
                                actions.setModalAllowBack(true),
                                actions.setExcludedComponentTypes(['Material', 'Binding Type', 'Printing Size', 'Printing Cost', 'Quantity', 'Production Timeline', 'Artwork Service', 'File Storage']),
                                actions.setAddComponentModalIsOpen(true),
                            ]}
                        >
                            <PlusIcon
                                height={15}
                                width={15}
                            />
                        </Button>
                    </Row>
                
                    <Droppable droppableId='bookletData.contentComponents.additionalComponents'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                {values.bookletData.contentComponents.additionalComponents.map((c, index) => (
                                    <DraggableComponent
                                        key={`${c._id ?? c.name}-${index}`}
                                        values={values}
                                        index={index}
                                        name={c.name}
                                        componentTypeName={c.componentTypeName}
                                        code={c.code}
                                        setFieldValue={setFieldValue}
                                        component={c}
                                        selectedComponents={values.bookletData.contentComponents.additionalComponents}
                                        supportNested={false}
                                        location={'bookletData.contentComponents.additionalComponents'}
                                        description={c.description}
                                    />                                                                                                  
                                ))}
                                
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Pairing*</h4>
                </Row>
                <Row className='m-1 p-4 rounded' style={{ backgroundColor: '#FFFFFF90' }}>
                   
                    <Col md='6' className='p-0 m-0'>
                        <Row className='p-0 m-0 mb-2'>
                            <small className='m-0 font-italic' style={{ color: '#00000090' }}>Cover: Material</small>
                        </Row>
                        <Field
                            className={'form-control h-auto p-1 mr-1'}
                            as='select'
                            style={{ width: '100%' }}
                            name={'selectedC2CPairing'}
                        >
                            <option
                                value={''}
                                disabled={(values.selectedC2CPairing)}
                            >
                                Please Select
                            </option>
                            {
                                values.bookletData.coverComponents.materialComponents.map((x, i) => {
                                    return (
                                        <option key={x._id} value={x.name}>{`${x.name}`}</option>
                                    );
                                })
                            }
                        </Field>
                    </Col>
                    <Col md='6'>
                        <div className='p-0 m-0 mb-2' style={{ color: '#00000090' }}>
                            <div><small className='m-0 font-italic'>Content: Material</small></div>
                            <div className='d-flex align-items-center'>
                                <small>in multiple of</small>
                                <Field
                                    className={'form-control h-auto p-1 ml-1'}
                                    as='select'
                                    style={{ width: 50 }}
                                    name={'bookletData.inMultipleOf'}
                                >
                                    <option value='2'>2</option>
                                    <option value='4'>4</option>
                                </Field>
                                {/* <Field
                                    className='ml-1 mr-1'
                                    type='checkbox'
                                    name={'bookletData.inMultipleOf'}
                                />
                                <small>2</small>
                                
                                <Field
                                    className='ml-2 mr-1'
                                    type='checkbox'
                                    name={'bookletData.inMultipleOf'}
                                />
                                <small>4</small> */}
                            </div>
                        </div>
                        <Row className='p-0 m-0 mb-2 bg-white rounded w-100 border'>
                            {values.bookletData.coverToContentPairing.find(x => x.cover === values.selectedC2CPairing)?.content.map((x, i) => {
                                const parent = values.bookletData.coverToContentPairing.findIndex(x => x.cover === values.selectedC2CPairing);
                                return (
                                    <Row
                                        key={x.name + '' + x._id}
                                        className='mx-4 d-flex mx-0 p-0 my-1 align-items-center w-100 justify-content-start'
                                    >
                                        <Col md='1' className='p-0 d-flex'>
                                            <Field
                                                type='checkbox'
                                                name={`bookletData.coverToContentPairing[${parent}].content[${i}].enabled`}
                                            />
                                        </Col>
                                        <Col md='8' className='pr-0'>
                                            <small>{x.name}</small>
                                            <div>
                                                <small>
                                                    Page Range:
                                                </small>
                                                <Field
                                                    key={`bookletData.coverToContentPairing[${parent}].content[${i}].minPP`}
                                                    type='input'
                                                    className='page-range-input'
                                                    name={`bookletData.coverToContentPairing[${parent}].content[${i}].minPP`}
                                                />
                                                <small>
                                                    to
                                                </small>
                                                <Field
                                                    key={`bookletData.coverToContentPairing[${parent}].content[${i}].maxPP`}
                                                    type='input'
                                                    className='page-range-input'
                                                    name={`bookletData.coverToContentPairing[${parent}].content[${i}].maxPP`}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </Row>
                        
                    </Col>

                </Row>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Quantity*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('bookletData.quantityComponents'),
                            actions.setSelectedComponentType('Quantity'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Quantity')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='bookletData.quantityComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                            {values.bookletData.quantityComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.bookletData.quantityComponents}
                                    supportNested={false}
                                    location={'bookletData.quantityComponents'}
                                    description={c.description}
                                />                                                                                                  
                            ))}
                                
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>


            {(role !== 'superadmin') && (
                <>
                    <div className='rounded bg-grey py-2 px-3 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Production Timeline</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.productionComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('productionComponents'),
                                    actions.setSelectedComponentType('Production Timeline'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Production Timeline')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='productionComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.productionComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.productionComponents}
                                            supportNested={false}
                                            location={'productionComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey py-2 px-3 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Artwork Service</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.artworkComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('artworkComponents'),
                                    actions.setSelectedComponentType('Artwork Service'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Artwork Service')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='artworkComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.artworkComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.artworkComponents}
                                            supportNested={false}
                                            location={'artworkComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey px-3 py-2 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>File Upload</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.fileStorageComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('fileStorageComponents'),
                                    actions.setSelectedComponentType('File Storage'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('File Storage')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='fileStorageComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.fileStorageComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.fileStorageComponents}
                                            supportNested={false}
                                            location={'fileStorageComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <CustomFieldSection
                        values={values}
                        setFieldValue={setFieldValue}
                        onAdd={() => [
                            actions.setCustomFieldModalIsOpen(true),
                        ]}
                    />
                </>
            )}
            
            <MarginSection
                disabledMarginProfile={packageDetails?.moduleRestrictions?.marginProfiles}
                values={values}
                marginsData={marginsData}
                role={role}
                setFieldValue={setFieldValue}
                marginProfilesData={marginProfilesData}
                onAddMargin={() => {
                    if (!packageDetails?.moduleRestrictions?.marginProfiles) {
                        actions.setModalAllowBack(true);
                        actions.setMarginProfileModalIsOpen(true);
                    } else {
                        actions.setModalAllowBack(true);
                        actions.setSelectedPricingRule('Margin');
                        actions.setPricingRuleModalIsOpen(true);
                    }
                }}
            />

            <DynamicPriceSection
                values={values}
                dynamicPriceData={dynamicPriceData}
                role={role}
                setFieldValue={setFieldValue}
                onAddDyamicPrice={() => [
                    actions.setModalAllowBack(true),
                    actions.setSelectedPricingRule('Dynamic Price'),
                    actions.setPricingRuleModalIsOpen(true),
                ]}
            />
            
            <ComponentSelector
                userId={userId}
                values={values}
                applicableSource={'booklet'}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />

            <PricingRuleSelector
                userId={userId}
                values={values}
                applicableSource={'booklet'}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />

            <MarginProfileSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />
        </>
    );
};

const mapStateToProps = state => ({
    packageDetails: state.packageDetails.data,
    marginProfilesData: state.marginProfiles.data,
    componentsTypeData: state.componentsType.data,
    role: state.role.details.user?state.role.details.user.role:'',
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookletComponentStructure));
